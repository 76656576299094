@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.homeContainer {
  /* The image used */
  top: 0;
  width: 100%;
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(./images/mountain.jpg);
  overflow: auto;
  overflow-x: hidden;
}

.button {
  font-family: "Manrope" !important;
}

#scrollBar.menuSticky {
  display: none;
  transition: all 1s ease-in-out;
}
