.animation__container {
  margin: 0 auto;
  position: relative;
  height: 50px;
}

.mail,
.check,
.bubble,
.warning {
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all ease-in-out 1s;
  transform: translateX(-50%) translateY(-50%);
}

.check,
.bubble {
  top: 50%;
  opacity: 0;
}

.send.bubble {
  top: 44%;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-delay: 0.95s;
  animation-name: Bubbles;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  opacity: 0;
}

.send.mail {
  animation-delay: 0.5s;
  animation-name: mail-anim;
  animation-duration: 2s;
  animation-iteration-count: 1;
  opacity: 0;
}

.send.check-stroke {
  stroke-dasharray: 150;
  animation-name: check-anim;
  animation-duration: 3s;
  animation-iteration-count: 1;
}
.modal__container__content-container {
  margin-bottom: 40px;
}

.modal__title {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 300;
  margin-bottom: 24px;
  text-align: center;
}

.modal__list {
  padding-left: 12px;
  list-style: none;
  li {
    position: relative;
    &:before {
      content: "•";
      position: absolute;
      width: 8px;
      height: 8px;
      left: -14px;
      color: #ffffff;
      top: 2px;
    }
  }
}

.modal__text {
  margin-bottom: 16px;
}

.modal__text--bold {
  font-weight: 500;
}

.modal__btn {
  background-color: #ffffff;
  color: #fff;
  text-decoration: none;
  width: 150px;
  display: block;
  text-align: center;
  padding: 16px 0;
  border-radius: 3px;
  margin: 0 32px 0 auto;
  font-weight: 400;
}

@keyframes Bubbles {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(1.5);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) scale(2.5);
  }
}

@keyframes mail-anim {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(0);
  }
  20% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1.1);
  }
  30% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
  40% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
  45% {
    opacity: 1;
    transform: translateX(-55%) translateY(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateX(200%) translateY(-50%) scale(1);
  }
}

@keyframes check-anim {
  0% {
    stroke-dashoffset: 150;
  }
  66% {
    stroke-dashoffset: 150;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes appear {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(0);
  }
  85% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
